.react-datepicker__month-container {
  box-shadow: 0 0 5px #ff9900;
  border: 1px solid #ff9900;
}
.datePickerHeaderContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.react-datepicker-popper {
  position: absolute;
  transform: translate3d(3.5px, 37.5px, 0px);
  inset: 0px auto auto 0px;
}

.datePickerHeaderSelects {
  display: flex;
  margin-top: 5px;
  gap: 1rem;
}

.datePickerHeaderSelects select {
  border-radius: 5px;
  cursor: pointer;
}

.react-datepicker__input-container input {
  border: none;
  outline: none;
  background-color: transparent;
  width: 95px;
}
