@media screen and (min-width: 200px) and (max-width: 1300px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 515px) and (max-width: 1110px) {
  html {
    font-size: 12px;
  }
}
/* @media screen and (min-width: 325px) and (max-width: 535px) {
    html {
      font-size: 10px;
    }
  } */
